export const t: Record<string, any> = {
  home: {
    en: "Home",
    fr: "Accueil",
  },
  catalogues: {
    en: "Catalogues",
    fr: "Catalogues",
  },
  applications: {
    en: "Applications",
    fr: "Applications",
  },
  resources: {
    en: "Resources",
    fr: "Ressources",
  },
  settings: {
    en: "Settings",
    fr: "Paramètres",
  },
  tryGenAILaunchpad: {
    en: "Visit the GenAI Launchpad",
    fr: "Visitez le Launchpad GenAI",
  },
  exploreCatalogues: {
    en: "Explore Catalogues",
    fr: "Explorer les catalogues",
  },
  apiCatalogue: {
    en: "API Catalogue",
    fr: "Catalogue d'API",
  },
  documentationCatalogue: {
    en: "Documentation Catalogue",
    fr: "Catalogue de documentation",
  },
  telusCatalogue: {
    en: "TELUS Catalogue",
    fr: "Catalogue TELUS",
  },
  unicornAiCardTitle: {
    en: "Try unicorn.ai",
    fr: "Essayer unicorn.ai",
  },
  unicornAiCardContent: {
    en: "Discover what unicorn.a can do for you.",
    fr: "Découvrez ce que unicorn.ai peut faire pour vous.",
  },
  templatesCardTitle: {
    en: "Templates",
    fr: "Modèles",
  },
  templatesCardContent: {
    en: "Use our templates to help you create your next component.",
    fr: "Utilisez nos modèles pour vous aider à créer votre prochaine composante.",
  },
  templatesCardButton: {
    en: "Templates",
    fr: "Modèles",
  },
  exploreApplications: {
    en: "Explore Applications",
    fr: "Explorer les applications",
  },
  apiTools: {
    en: "API Tools",
    fr: "Outils d'API",
  },
  apiMarketplace: {
    en: "API Marketplace",
    fr: "Marché des API",
  },
  cioMentorship: {
    en: "CIO x TEC Mentorship",
    fr: "Mentorat CIO x TEC",
  },
  cloudManagement: {
    en: "Cloud Management",
    fr: "Gestion du cloud",
  },
  dynatraceMonitor: {
    en: "Dynatrace Monitor",
    fr: "Moniteur Dynatrace",
  },
  externalHealthcheck: {
    en: "External Healthcheck",
    fr: "Vérification des statuts externe",
  },
  securityMetrics: {
    en: "Security Metrics",
    fr: "Métriques de sécurité",
  },
  genAILaunchpad: {
    en: "GenAI Launchpad",
    fr: "Launchpad GenAI",
  },
  tdrmIssues: {
    en: "TDRM Issues",
    fr: "Problèmes TDRM",
  },
  trackComponentCardTitle: {
    en: "Start tracking your component",
    fr: "Commencez à suivre votre composante",
  },
  trackComponentCardContent: {
    en: "Register your existing component with the Simplify Software Catalogue.",
    fr: "Enregistrez votre composante avec le catalogue de logiciels Simplify.",
  },
  trackComponentCardButton: {
    en: "Register Component",
    fr: "Enregistrer votre composante",
  },
  iccComponentCardTitle: {
    en: "Identity Command Center",
    fr: "Centre de commande d'identité",
  },
  iccComponentCardContent: {
    en: "Learn more about the Identity Command Center.",
    fr: "En savoir plus sur le Centre de commande d'identité.",
  },
  iccComponentCardButton: {
    en: "Identity Command Center",
    fr: "Centre de commande d'identité",
  },
  tascComponentCardTitle: {
    en: "Trust & Safety Command Center",
    fr: "Centre de commandement de confiance et de sécurité",
  },
  tascComponentCardContent: {
    en: "Learn more about the Trust & Safety Command Center.",
    fr: "Apprenez-en davantage sur le centre de commande Trust & Safety.",
  },
  tascComponentCardButton: {
    en: "Trust & Safety Command Center",
    fr: "Centre de commandement de confiance et de sécurité",
  },
  aacTemplateLanding: {
    en: "Starter Templates",
    fr: "Modèles de démarrage",
  },
  productInventory: {
    en: "CIO Product Inventory",
    fr: "CIO Inventaire des produits",
  },
  search: {
    en: "Search",
    fr: "Rechercher",
  },
  simplifyVideoLibrary: {
    en: "Simplify Video Library",
    fr: "Bibliothèque vidéo de Simplify",
  },
  browseOurVideoLibrary: {
    en: "Browse our video library",
    fr: "Parcourez notre bibliothèque vidéo",
  },
  videoLibraryDescription: {
    en: "Find, watch and share videos on how we work and do things at Telus.",
    fr: "Trouvez, regardez et partagez des vidéos sur la façon dont nous travaillons et faisons les choses chez Telus.",
  },
  feedback: {
    en: "Feedback",
    fr: "Retour",
  },
  lab: {
    en: "Lab",
    fr: "Laboratoire",
  },
  dse: {
    en: "DSE Analytics Hub",
    fr: "Centre d'analyse DSE",
  },
};
