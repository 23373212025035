import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'dse-analytics-frontend',
});

export const browseProjectRouteRef = createSubRouteRef({
  id: 'browse-project',
  parent: rootRouteRef,
  path: '/browse-project/:projectType',
});
export const createProjectRouteRef = createSubRouteRef({
  id: 'create-project',
  parent: rootRouteRef,
  path: '/create-project',
});
export const editProjectRouteRef = createSubRouteRef({
  id: 'edit-project',
  parent: rootRouteRef,
  path: '/update-project/:projectType/:projectID',
});
export const viewProjectRouteRef = createSubRouteRef({
  id: 'view-project',
  parent: rootRouteRef,
  path: '/view-project/:projectType/:projectID',
});
