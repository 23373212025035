import {
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
  createApiFactory,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import {
  browseProjectRouteRef,
  createProjectRouteRef,
  editProjectRouteRef,
  rootRouteRef,
  viewProjectRouteRef,
} from './routes';
import { dseOnbaordApiRef } from './api/apiClient';
import { DseOnbaordingClient } from './api/dseOnboardingClient';

export const dseAnalyticsFrontendPlugin = createPlugin({
  id: 'dse-analytics-frontend',
  apis: [
    createApiFactory({
      api: dseOnbaordApiRef,
      deps: {
        discovery: discoveryApiRef,
        identityApi: identityApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discovery, identityApi, fetchApi }) =>
        new DseOnbaordingClient(discovery, fetchApi, identityApi),
    }),
  ],
  routes: {
    root: rootRouteRef,
    browse: browseProjectRouteRef,
    create: createProjectRouteRef,
    edit: editProjectRouteRef,
    view: viewProjectRouteRef,
  },
});

export const DseAnalyticsFrontendPage = dseAnalyticsFrontendPlugin.provide(
  createRoutableExtension({
    name: 'DseAnalyticsFrontendPage',
    component: () => import('./components/Homepage').then(m => m.Homepage),
    mountPoint: rootRouteRef,
  }),
);

export const BrowseProjectPage = dseAnalyticsFrontendPlugin.provide(
  createRoutableExtension({
    name: 'BrowseProjectPage',
    component: () =>
      import('./components/ProjectsPage').then(m => m.ManageProject),
    mountPoint: rootRouteRef,
  }),
);

export const ManageProjectPage = dseAnalyticsFrontendPlugin.provide(
  createRoutableExtension({
    name: 'ManageProjectPage',
    component: () =>
      import('./components/ProjectsPage').then(m => m.ManageProject),
    mountPoint: rootRouteRef,
  }),
);
